import { PageProps } from 'gatsby';
import { NoteData } from 'packages/innedit';
import { parse } from 'query-string';
import React, { FC } from 'react';

import Form from '~/containers/Espace/Form';
import note from '~/params/note.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceNoteCreate: FC<PageProps & EspaceProps & UserProps> =
  function ({ espace, location: { search }, user }) {
    const model = new NoteData({ espaceId: espace.id, params: note });

    let params;
    if (search) {
      params = parse(search);
    }

    return (
      <TemplateEspace espace={espace} user={user}>
        <Form
          docId={model.getNewDocId()}
          initializeData={params}
          model={model}
          type="create"
        />
      </TemplateEspace>
    );
  };

export default requireEspace(PageEspaceNoteCreate);
